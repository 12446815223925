import React, { useState, useEffect } from 'react';
import { Button, Card } from 'react-bootstrap';
import { useShoppingCart } from '../../context/ShoppingCartContext';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { FaStar } from 'react-icons/fa';

export function StoreItem({ id, name, quantities, shortdescription, price, pricebefore, stock,
  rating, reviewcount, review1, review2, review3, review4, review5,customername1,
  customername2, customername3, customername4, customername5, customerRating1, 
  customerRating2,customerRating3,customerRating4, customerRating5,  imgUrl, link, showLink }) {
  const {
    getItemQuantity,
    increaseCartQuantity,
    decreaseCartQuantity,
    removeFromCart,
  } = useShoppingCart();
  const quantity = getItemQuantity(id);

  const [showPopup, setShowPopup] = useState(false);
  const [showReview, setShowReview] = useState(false);
  const [daysSinceStart, setDaysSinceStart] = useState(false);

  useEffect(() => {
    const startCount = parseInt(reviewcount); 
    const startDate = new Date('2024-06-05');
    const today = new Date();
    const millisecondsPerDay = 1000 * 60 * 60 * 500;
    const days = Math.floor((today - startDate) / millisecondsPerDay);
    const totalCount = days + startCount; 
    setDaysSinceStart(totalCount);
  }, [reviewcount]);
  
  const handleBuyNow = () => {
    if (stock > 0) {
      const totalQuantityInCart = quantity + 1;
      if (totalQuantityInCart <= stock) { 
        increaseCartQuantity(id);
        setTimeout(() => setShowPopup(true), 100);
      } else {
        //Ignore
      }
    } else {
      //Ignore
    }
  };
  
  const toggleReview = () => {
    setShowReview(!showReview);
  };

  const customStarStyle = { color: 'gold', fontSize: '20px', marginBottom: '5px' };

  const createStars = (rating) => {
    const starIcons = [];
    for (let i = 0; i < rating; i++) {
        starIcons.push(<FaStar key={i} style={customStarStyle} />);
    }
    return starIcons;
  };

  return (
      <Card className="h-100">
        <div className="storeItem-top"></div>
        <Card.Img
          variant="top"
          src={imgUrl}
          alt={name}
          style={{ maxHeight: '300px', objectFit: 'contain', width: '100%' }}
        />
        <Card.Body className="d-flex flex-column fixed-height">
          <Card.Title className="d-flex justify-content-between align-items-baseline mb-2">
            <h1 className="fs-2">{name}</h1>
          </Card.Title>
          <Card.Subtitle  className="d-flex justify-content-between align-items-baseline mb-3">
          <span className="ms-2 text-muted">
            {pricebefore !== price && <del>{pricebefore} kr</del>}{" "}
            <span className={pricebefore !== price ? "text-danger" : ""}>
              {price} kr
            </span>{" "}
            ({quantities}-Pack)
          </span>
          </Card.Subtitle>
          <Card.Subtitle className="d-flex justify-content-between align-items-baseline mb-3">
            <span className="ms-2 text-muted">
            {createStars(rating)}({daysSinceStart})
              {reviewcount > 10 && (
                <span onClick={toggleReview} className="ms-2 text-muted" style={{ cursor: 'pointer' }}>
                  {showReview ? <span style={{ color: 'grey', textDecoration: 'underline' }}>Dölj</span> : <span style={{ color: '#0d6efd'}}>Recensioner</span>}
                </span>
              )}
            </span>
          </Card.Subtitle>
          {showReview && (
            <Card.Text className="d-flex justify-content-between align-items-baseline mb-4">
              <div className="reviews">
                <span> {createStars(customerRating1)}  </span><br></br>
                <span> <strong>{customername1}</strong></span>
                <p>{review1}</p>
                <span> {createStars(customerRating2)}  </span><br></br>
                <span> <strong>{customername2}</strong></span>
                <p>{review2}</p>
                <span> {createStars(customerRating3)}  </span><br></br>
                <span> <strong>{customername3}</strong></span>
                <p>{review3}</p>
                <span> {createStars(customerRating4)}  </span><br></br>
                <span> <strong>{customername4}</strong></span>
                <p>{review4}</p>
                <span> {createStars(customerRating5)}  </span><br></br>
                <span> <strong>{customername5}</strong></span>
                <p>{review5}</p>
              </div>
            </Card.Text>
          )}
          <Card.Text className="d-flex justify-content-between align-items-baseline mb-4">
              <span>{shortdescription}</span>
          </Card.Text>
            {showLink && link !== "" ? (
            <Card.Text>
              <a href={link} className='mb-3'>
                <span style={{ color: '#0d6efd' }}>Läs mer och se fler förpackningar</span>
              </a>
            </Card.Text>
          ) : (
            <Card.Text></Card.Text>
          )}
        <div className="mt-auto">
            {quantity === 0 ? (
              <Button
                className="w-100"
                onClick={handleBuyNow}
                disabled={stock === 0}
                style={{ backgroundColor: stock === 0 ? 'grey' : 'black' }}
              >
                {stock === 0 ? 'Slut i lager' : <h1 style={{ fontSize: '12px', display: 'inline', margin: 0, padding: 0 }}>{`Köp ${name}`}</h1>}
              </Button>
            ) : (
              <div
                className="d-flex align-items-center flex-column"
                style={{ gap: '.5rem' }}
              >
                <div className="d-flex align-items-center justify-content-center" style={{ gap: '.5rem' }}>
                  <Button onClick={() => decreaseCartQuantity(id)} style={{ backgroundColor: 'black' }}>-</Button>
                  <div>
                    <span className="fs-3">{quantity}</span> i varukorg
                  </div>
                  {quantity < stock ? (
                    <Button onClick={() => increaseCartQuantity(id)} style={{ backgroundColor: 'black' }}>+</Button>
                  ) : (
                    <Button disabled style={{ backgroundColor: 'grey' }}>+</Button>
                  )}
                </div>
                <Button onClick={() => removeFromCart(id)} variant="danger" size="sm" style={{ backgroundColor: 'black' }}>Ta bort</Button>
              </div>
            )}
        </div>
        </Card.Body>
        {showPopup && (
          <Popup
            open={showPopup}
            onClose={() => setShowPopup(false)}
            modal
            closeOnDocumentClick
            contentStyle={{
              backgroundColor: 'white',
              color: 'black',
              padding: '15px',
              borderRadius: '5px',
              boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
              width: '180px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
            }}
          >
            <span>Produkten har lagts till i din varukorg.</span>
          </Popup>
        )}
      </Card>
  );
}