import React from "react";
import Image from '../../assets/kamagra-oral-jelly-week-pack-sildenafil-100mg.jpg';

const NotFound = () => {
  return (
    <main className="page-not-found">
    <header className="page-not-found-main">
      <article className="nav-center">
      <a href='/' className="nav-link">Hem / </a>
      <a href='*' className="nav-link">404</a>
      </article>
      <article className="page-not-found-child">
        <h1>404 Sidan kunde inte hittas</h1>
        <h2>Oj! Det verkar som att du har nått en sida som inte finns.</h2>
        <img src={Image} alt="Page Not Found" />
      </article>
      <article>
          <a href="/">WEBBSHOP</a> | <a href="/omoss">OM OSS / KONTAKT</a> | <a href="/faq">FAQ</a>
      </article>
      </header>
    </main>
  );
};

export default NotFound;

